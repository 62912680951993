<template>
  <div>
    <b-button @click="$router.push('/perfil')">Voltar</b-button>
    <Card class="py-3 mt-2">
      <b-row>
        <b-col>
          <label>Perfil</label>
          <b-input v-model="model.name"></b-input>
        </b-col>
        <b-col>
          <label>Tipo do Perfil</label>
          <b-form-select
            :options="profileTypesSelectOptions"
            v-model="model.profile_type_id"
          ></b-form-select>
        </b-col>
        <b-col v-if="editing">
          <label>Ativo</label>
          <b-form-select
            :options="[{text:'Sim', value:'1'}, {text:'Não', value:'0'}]"
            v-model="model.active"
          ></b-form-select>
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button v-if="!editing" @click="save_profile">Criar</b-button>
        <b-button v-else @click="update_profile">Alterar</b-button>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      model: {
        company_id: "",
        unity_id: this.$store.state.auth.currentUser.unity_id,
        profile_type_id: "",
        name: "",
        default: "",
        visible: "",
        profile_id: "", //?????????? duplicado?
        group_receiving_id: "",
        center_cost_id: "",
        active: '1'
      },
    };
  },
  computed: {
    profileTypes() {
      return this.$store.state.configurations.profileTypes;
    },
    profileTypesSelectOptions() {
      return _.map(this.profileTypes, (type) => {
        return { text: type.type, value: type.id };
      });
    },
  },
  methods: {
    async save_profile() {
      await this.$store.dispatch("configurations/save_profile", this.model);
      this.$router.push("/configuracoes/perfil");
    },
    async update_profile() {
      await this.$store.dispatch("configurations/update_profile", this.model);
      this.$router.push("/configuracoes/perfil");
    },
  },
  async mounted() {
    const profileId = this.$route.params.profileId;
    if (profileId) {
      const profile = await this.$store.dispatch(
        "configurations/get_profile_by_id",
        profileId
      );      
      this.model = profile[0];
      this.editing = true;
    }
    this.$store.dispatch(
      "configurations/add_edit_profile_init",
      this.model.unity_id
    );
  },
};
</script>

<style>
</style>